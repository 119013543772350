import { useState } from "react";
import React from "react";

function About() {
  const data = [
    {
      img: "/img/works/zankonas.webp",
      title: "Zanko nas",
      description:
        "is a platform I created to support 12th-grade students in Kurdistan in finding the best-fit universities based on their grades. With an annual user base of over 22,000, Zankonas lists universities tailored to each student's academic achievements, making higher education choices simpler and more accessible",
      link: "https://zankonas.com/",
    },
    {
      img: "/img/works/asanbot.PNG",
      title: "Asanbot",
      description:
        "Asanbot is an automated tool I developed for handling comment replies on Meta platforms like Instagram and Facebook. With a backend capable of processing over 1.5 million requests daily at just $200 per month, it’s optimized for efficient, large-scale social media management.",
      link: "https://www.asanbot.com/home",
    },

    // {
    //   img: "/img/works/fd.PNG",
    //   title: "foreign doctors",
    //   description:
    //     "Foreign Doctors is a platform designed to assist international doctors with visa applications in Kurdistan. It features comprehensive admin panels utilized by the Ministry of Interior, Ministry of Health, and the parent company for streamlined management and oversight.",
    //   link: "https://foringdoc-6930f98965a1.herokuapp.com/",
    // },
    {
      img: "/img/works/rekan.PNG",
      title: "Rekan Travel",
      description:
        "This website, designed for Rekan Travel, showcases the agency’s services and information, providing an online presence that enhances their professional image and accessibility",
      link: "https://rekantravel.com/",
    },

    {
      img: "/img/works/botakam.PNG",
      title: "Botakam",
      description:
        "Botakam is a social media automation tool I developed for handling comment replies, currently supporting over 500 Facebook pages, making it an essential tool for efficient and large-scale engagement.",
      link: "https://botakam.com/home",
    },
    {
      img: "/img/works/linkakam.PNG",
      title: "linkakam",
      description:
        "Linkakam enables users to consolidate all their social media links into a single, shareable link, which can be displayed as a QR code or added to a bio for easy access and sharing",
      link: "https://linkakam.com/en/home",
    },
    // {
    //   img: "/img/works/1001bm-min.PNG",
    //   title: "1001 Business Men",
    //   description:
    //     "A platform dedicated to the annual gathering of 1001 influential businessmen, fostering opportunities for networking, collaboration, and discussions on business insights and growth strategies.",
    //   link: "https://1001businessmen.com/",
    // },
    {
      img: "/img/works/klil.PNG",
      title: "Klil Magazine",
      description:
        "Klil Magazine is a comprehensive news website featuring a full CMS admin panel and an integrated advertisement section, offering robust content management and monetization capabilities.",
      link: "https://www.klilmagazine.com/home",
    },
    {
      img: "/img/works/qalandar.PNG",
      title: "Dr.Qalandar",
      description:
        "This website, created for Dr. Qalandar, serves as a platform for him to share blogs, videos, and showcase his certifications, enhancing his online presence and professional reach.",
      link: "https://drqalandar.com/",
    },
    // {
    //   img: "/img/works/fastlink.PNG",
    //   title: "fast link",
    //   description:
    //     "Developed at Devspace for Kurdistan’s leading internet provider, Fastlink, this website features a robust CMS admin panel and comprehensive SEO, ensuring efficient management and enhanced online visibility.",
    //   link: "https://fast-link.com/",
    // },

    {
      img: "/img/works/cccht.PNG",
      title: "Heartland Alliance",
      description:
        "his website, developed for Heartland Alliance, focuses on preventing human trafficking by spreading awareness and offering resources to empower and protect vulnerable individuals.",
      link: "https://cccht.info/",
    },
    {
      img: "/img/works/bergamo.PNG",
      title: "Bergamo Iraq",
      description:
        "Bergamo Iraq’s website showcases their range of ice cream machines, supporting their operations across Iraq and Germany by providing a professional display for their products.",
      link: "https://bergamoiraq.com/",
    },
  ];
  const [number, setnumber] = useState(6);

  return (
    <div className="mt-24">
      <p className="text-5xl text-white mb-4 font-ravenna">Portfolio</p>

      <div className="md:m-8 m-2 grid grid-cols-1 md:grid-cols-3 gap-6   ">
        {data.slice(0, number).map((item, index) => (
          <a
            href={item.link} rel="noreferrer"
            target="_blank"
            key={index}
            className={`w-full  relative bg-gray-300  bg-opacity-20 overflow-hidden rounded-3xl  hover:shadow-2xl ${
              index === data.length - 1 ? "hidden md:block" : ""
            } group`}
          >
            <div className="absolute p-4 bg-black bg-opacity-70 w-full h-full hidden md:group-hover:flex flex-col justify-start items-start">
              <p className="text-base  md:text-xl font-ravenna font-normal text-white  ">
                {item.title}
              </p>
              <p className="text-sm  md:text-lg   text-white text-left">
                {item.description}
              </p>
            </div>
            <img
              src={item.img}
              className="w-full      object-cover"
              alt={item.title}
            />{" "}
            <div className="  p-4   w-full h-full  md:hidden shadow-2xl  flex-col justify-start items-start">
              <p className="text-xl  md:text-xl font-ravenna font-normal text-white  ">
                {item.title}
              </p>
              <p className="text-base  md:text-lg   text-white text-left">
                {item.description}
              </p>
            </div>
          </a>
        ))}
      </div>
      <div>
        <button
          className="bg-white hover:bg-[#ececec] mt-4 text-[#2d0e7b] font-ravenna text-xl py-2 px-8  rounded-lg"
          onClick={() => {
            if (number === 6) {
              setnumber(12);
            } else {
              setnumber(6);
            }
          }}
        >
          {number === 6 ? "View More!" : "View Less"}
        </button>
      </div>
    </div>
  );
}

export default About;
