import React, { useState } from "react";
import "../style/MenuComponent.css"; // assuming you put your css in MenuComponent.css

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="w-full   h-24  bg-gradient-to-b from-[#1d1d1f] to-transparent    z-10  fixed  flex justify-center items-center">
      <div className="    w-[90vw]    flex  justify-between items-center mt-8 mx-8">
        <div className="w-12 relative">
          <div className={`text_container    `}>
            <p
              className={` z-90 text-4xl font-ravenna ${
                isActive ? "text-[#2d0e7b]" : "text-white"
              }`}
            >
              Paiwand
            </p>
          </div>{" "}
        </div>
        <div className="w-12 z-50 relative  ">
          <div
            id="toggle"
            className={`button_container z-50    ${isActive ? "active" : ""}`}
            onClick={handleToggleClick}
          >
            <span className="top"></span>
            <span className="middle"></span>
            <span className="bottom"></span>
          </div>
        </div>

        <div id="overlay" className={`overlay ${isActive ? "open" : ""}`}>
          <nav className="overlay-menu">
            <ul>
              <li>
                <a onClick={handleToggleClick} href="#Home">
                  Home
                </a>
              </li>
              <li>
                <a onClick={handleToggleClick} href="#About">
                  About
                </a>
              </li>
              <li>
                <a onClick={handleToggleClick} href="#Skills">
                  Skills
                </a>
              </li>
              <li>
                <a onClick={handleToggleClick} href="#Portfolio">
                  Portfolio
                </a>
              </li>{" "}
              <li>
                <a onClick={handleToggleClick} href="#Contact">
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
