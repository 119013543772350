import React from "react";

function about() {
  const data = [
    { img: "/img/icons/js.PNG", title: "Javascript" },
    { img: "/img/icons/python.png", title: "Python" },
    { img: "/img/icons/php.png", title: "PHP" },
    { img: "/img/icons/java.png", title: "Java" },
    { img: "/img/icons/react.png", title: "React js/ts" },
    { img: "/img/icons/laravel.png", title: "Laravel" },
    { img: "/img/icons/vue.png", title: "Vue" },
    { img: "/img/icons/node.png", title: "Node" },
    { img: "/img/icons/WordPress.png", title: "WordPress" },
    { img: "/img/icons/sql.png", title: "SQL" },
    { img: "/img/icons/mongo db.png", title: "Mongo DB" },
    { img: "/img/icons/redux.png", title: "Redux" },
    { img: "/img/icons/AWS.png", title: "AWS" },
    { img: "/img/icons/FB.png", title: "Firebase" },
    { img: "/img/icons/adobe.png", title: "Adobe programs" },
  ];
  return (
    <div   className=" mt-24">
      <p className="text-5xl text-white mb-4 font-ravenna">My Skills</p>

      <div className="md:m-8 m-2 grid grid-cols-2 md:grid-cols-5 gap-6   ">
        {/* card */}
        {data.map((item, index) => (
          <div
            key={index}
            className={`w-full aspect-square bg-gray-300 rounded-3xl bg-opacity-20 hover:shadow-2xl ${
              index === data.length - 1 ? "hidden md:block" : ""
            }`}
          >
            <div className="m-4 flex flex-col h-full justify-center items-center">
              <img
                src={item.img}
                className="w-[70%] aspect-square object-contain"
                alt={item.title}
              />
              <p className="text-2xl font-ravenna text-white m-4">
                {item.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default about;
