import React from "react";
import Hero from "../Componant/Hero";

import Contact from "../Componant/Contact/Contact";

import About from "../Componant/about/About";
import Skills from "../Componant/Skills/Skills";
import Portfolio from "../Componant/Portfolio/Portfolio";
import Thanks from "../Componant/Thanks/Thanks";

 
import Header from "../Componant/Header";

const Home = () => {
  return (
    <div className="z-30 relative">
      <Header />
      <Hero />
      <div id="About"></div>
      <About /> <div id="Skills"></div>
      <Skills />
      <div id="Portfolio"></div>
      <Portfolio />
      <div id="Contact"></div>
      <Contact />
      <Thanks />
    </div>
  );
};

export default Home;
