import React from "react";
import Typewriter from "../Componant/Typewriter";
import "../style/scrolldown.css";

function Hero() {
  return (
    <div id="Home" className="h-screen flex items-center justify-center relative">
      {/* <img
        className="z-0 opacity-60 w-[900px] absolute"
        src="/img/dev1.png"
        alt="peshawa group"
      /> */}
      <div className="">
        <p className="text-8xl font-ravenna text-white ">
          <Typewriter text="hello my name is paiwand" delay={50} />
        </p>
      </div>

      <div id="sd-container" className="z-30 sdContainer">
        <div className="arrow"></div>
        <div className="arrow"></div>
      </div>
    </div>
  );
}

export default Hero;
