import React from "react";

import { LuGraduationCap } from "react-icons/lu";
import { IoCodeSlashOutline } from "react-icons/io5";

 function about() {
  return (
    <div className=" mt-24">
      <div className="md:ml-8 ml-2 flex flex-col md:flex-row ">
        <div className="w-full md:w-[50%] flex flex-col justify-center items-start ">
          <h2 className="text-5xl text-white mb-4 font-ravenna">about me</h2>
          <p className="text-xl  text-white text-left font-normal justify-start">
            Hello! I'm Paiwand, a Full Stack Web and Mobile App Developer with a
            passion for building robust, scalable applications. Specializing in
            web and database development, I bring expertise across all stages of
            the development cycle for dynamic web projects.
            <br />
            <br />
            With over two years of experience, I previously served as a
            supervisor in the development department at
            <span className="font-bold  ">Peshawa Group</span> and now work as a
            Full Stack Developer at
            <span className="font-bold  ">Devspace</span> . My skills also
            include cloud server management, ensuring applications are optimized
            for performance and security.
            <br />
            <br />I am driven by a commitment to delivering high-quality,
            impactful solutions that exceed client expectations and contribute
            to project success.
          </p>
          <div className="text-white flex flex-col md:flex-row gap-4  mt-4  w-full      items-center   ">
            <div className="w-full md:w-1/2    py-2 rounded-lg text-xl bg-gray-300 hover:bg-gray-100 hover:shadow-xl hover:bg-opacity-30 flex justify-center items-center   bg-opacity-20">
              <LuGraduationCap className="m-2" />
              Bachelor's degree in IT
            </div>

            <div className="w-full md:w-1/2 py-2 rounded-lg text-xl bg-gray-300 hover:bg-gray-100 hover:shadow-xl hover:bg-opacity-30 flex justify-center items-center   bg-opacity-20">
              <IoCodeSlashOutline className="m-2" />
              Four years of academic experience
            </div>
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <img src="/img/paiwand.webp" alt="paiwand bahman jawhar " />
        </div>
      </div>
    </div>
  );
}

export default about;
