import React from "react";
import { FaFacebookF } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import { RiMailLine } from "react-icons/ri";
import { FiGithub } from "react-icons/fi";
import { CiFileOn } from "react-icons/ci";

function Contact() {
  return (
    <div className="    p-12  mt-36 mb-36">
      <div className="    flex flex-col  w-full items-center   justify-center">
        <div>
          <h2 className="text-5xl text-white mb-4 font-ravenna  ">
            Contact Me
          </h2>
        </div>
        <div className="text-white flex flex-col md:flex-row gap-4 w-full  md:w-2/5 items-center    ">
          <a
            href="mailto:contact@paiwand.dev"
            className="w-full  md:w-[45%] py-2 rounded-lg text-xl bg-gray-300 hover:bg-gray-100 hover:shadow-xl hover:bg-opacity-30 flex justify-center items-center   bg-opacity-20"
          >
            <RiMailLine className="m-2" />
            contact@paiwand.dev
          </a>

          <a
            href="tell:9647711576268"
            className="w-full  md:w-[45%] py-2 rounded-lg text-xl bg-gray-300 hover:bg-gray-100 hover:shadow-xl hover:bg-opacity-30 flex justify-center items-center   bg-opacity-20"
          >
            <IoCallOutline className="m-2" />
            0964 771 157 62 68
          </a>
        </div>
        <div className="text-white flex flex-col md:flex-row gap-4  mt-4  w-full  md:w-2/5   items-center   ">
          <a
            href="https://www.facebook.com/paiwand.bahman.5/"
            className="w-full  md:w-[45%] py-2 rounded-lg text-xl bg-gray-300 hover:bg-gray-100 hover:shadow-xl hover:bg-opacity-30 flex justify-center items-center   bg-opacity-20"
          >
            <FaFacebookF className="m-2" />
            @paiwand bahman
          </a>

          <a
            href="https://github.com/paiwandbahman"
            className="w-full  md:w-[45%] py-2 rounded-lg text-xl bg-gray-300 hover:bg-gray-100 hover:shadow-xl hover:bg-opacity-30 flex justify-center items-center   bg-opacity-20"
          >
            <FiGithub className="m-2" />
            paiwand bahman
          </a>
        </div>
        <div className="text-white flex flex-col md:flex-row gap-4  mt-4  w-full  md:w-2/5 justify-center  items-center   ">
          <a
            href="/img/paiwand bahman.pdf"
            target="_blank"
            className="w-full  md:w-[45%] py-2 rounded-lg text-xl bg-gray-300 hover:bg-gray-100 hover:shadow-xl hover:bg-opacity-30 flex justify-center items-center   bg-opacity-20"
          >
            <CiFileOn className="m-2" />
            CV
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
