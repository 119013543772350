import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import "./App.css";
import Home from "./Page/Home";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingContainer = styled.div`
  animation: ${rotate} 20s linear infinite;
  height: 30vmax;
  width: 30vmax;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Blob = styled.div`
  pointer-events: none;
  background-color: white;
  height: 100%;
  aspect-ratio: 1;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: linear-gradient(
    90deg,
    rgba(26, 81, 205, 1) 0%,
    rgba(121, 9, 98, 1) 41%,
    rgba(196, 196, 196, 1) 71%,
    rgba(146, 3, 117, 1) 100%
  );
  opacity: 0.8;
`;

const Blur = styled.div`
  pointer-events: none;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  backdrop-filter: blur(150px);
`;

const Container = styled.div`
  pointer-events: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
`;

function App() {
  const blobRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const blobContainer = blobRef.current;

      const adjustedLeft = Math.min(
        window.innerWidth - blobContainer.clientWidth,
        Math.max(0, clientX - blobContainer.clientWidth / 2)
      );
      const adjustedTop = Math.min(
        window.innerHeight - blobContainer.clientHeight + window.scrollY,
        Math.max(
          window.scrollY,
          clientY + window.scrollY - blobContainer.clientHeight / 2
        )
      );

      blobContainer.animate(
        {
          left: `${adjustedLeft}px`,
          top: `${adjustedTop}px`,
        },
        { duration: 3000, fill: "forwards" }
      );
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="App">
      <Wrapper>
        <Home />
        <Container>
          <RotatingContainer ref={blobRef}>
            <Blob />
          </RotatingContainer>
          <Blur />
        </Container>
      </Wrapper>
    </div>
  );
}

export default App;
