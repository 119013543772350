import React from "react";
import { FaChevronUp } from "react-icons/fa6";

function Thanks() {
  return (
    <div>
      <div className="flex justify-around  items-center  m-12">
        <div className="text-white w-1/3  text-left hidden md:block ">
          © 2024 paywand.dev
        </div>
        <h2 className="text-2xl  w-3/4 md:w-1/3   text-white mb-4 font-ravenna">
          Thanks For Scrolling
        </h2>
        <div className="w-1/4 md:w-1/3  items-center flex justify-end">
          <a
            href="#Home"
            className="text-[#2d0e7b]    bg-white hover:shadow-lg hover:bg-gray-200  w-12 h-12 flex justify-center items-center rounded-full"
          >
            <FaChevronUp />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Thanks;
